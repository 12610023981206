<template>
  <landing hero-image="/images/hero-image-1.jpg">
    <mobile-full-page-wrapper :go-to-id="landingFieldsId" show-arrow>
      <header class="header">
        <business-logo class="business-logo"/>
        <typography
          type="h3"
          class="page-title"
          light
        >Kia ora, let's get you rewarded with Fly Buys points or Airpoints Dollars&trade;</typography>
        <typography
          light
        >Please use the same email address used for your {{ brand }} account, or to receive your {{ brand }} invoice.</typography>
      </header>
    </mobile-full-page-wrapper>

    <mobile-full-page-wrapper :id="landingFieldsId">
      <form class="form" @submit.prevent="onLandingSubmit">
        <div class="inputs">
          <text-input
            :maxlength="250"
            v-model.trim.lazy="name"
            :rules="[
              () => $v.name.required || 'This field is required',
            ]"
            label="Full name"
            name="ApplicantName"
            class="name-text-field"
            @blur="$v.name.$touch()"
          />
          <text-input
            v-model.trim.lazy="email"
            :maxlength="320"
            :rules="[
              () => $v.email.required || 'This field is required',
              () => $v.email.email || 'Please provide a valid email address',
            ]"
            label="Email"
            name="ApplicantEmail"
            type="email"
            no-margin
            @blur="$v.email.$touch()"
          />
        </div>
        <v-layout justify-center>
          <v-flex xs10 md12>
            <round-button
              :disabled="$v.$invalid"
              type="submit"
              name="GetStartedButton"
            >{{buttonLabel}}</round-button>
          </v-flex>
        </v-layout>
      </form>
    </mobile-full-page-wrapper>
  </landing>
</template>

<script>
import Landing from '@/components/templates/Landing.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import Typography from '@/components/atoms/Typography.vue';
import BusinessLogo from '@/assets/icons/z-business-logo.svg';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { FORM } from '@/constants/routes';
import { required } from 'vuelidate/lib/validators';
import { email } from '@/validators';
import { FIELD_LANDING } from '@/constants/html-ids';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Checkbox,
    Landing,
    RoundButton,
    TextInput,
    Typography,
    BusinessLogo,
    MobileFullPageWrapper
  },
  computed: {
    name: {
      get() {
        return this.$store.state.landing.name;
      },
      set(value) {
        this.$v.name.$touch();
        this.$store.dispatch('landing/setName', value);
      }
    },
    email: {
      get() {
        return this.$store.state.landing.email;
      },
      set(value) {
        this.$v.email.$touch();
        this.$store.dispatch('landing/setEmail', value);
      }
    },
    buttonLabel() {
      if (this.$store.state.landing.sectionComplete) {
        return 'Continue';
      }
      return 'Get started';
    },
    landingFieldsId: () => FIELD_LANDING,
    brand: () => BRAND
  },
  methods: {
    async onLandingSubmit() {
      await this.$store.dispatch('landing/changeSectionComplete', true);

      this.$router.push(FORM);
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.header {
  @include sm {
    margin: rem(20px 0 70px);
  }
}

.business-logo {
  @include xs-only {
    position: absolute;
    top: rem(40px);
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include sm {
    margin-bottom: rem(65px);
  }
}

.page-title {
  max-width: 100%;
  width: rem(260px);
  margin: rem(0 auto 10px auto);

  @include sm {
    margin: rem(0 0 10px 0);
    width: auto;
  }
}

.form {
  @include sm {
    padding-bottom: rem(80px);
  }
}

.inputs {
  margin: rem(85px 0 65px);

  .name-text-field {
    margin-bottom: rem(50px);
  }
}
</style>
